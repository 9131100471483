import { Button, LinearProgress } from "@material-ui/core";
import React, { Component } from "react";
import downloadjs from "downloadjs";
import axios from "utils/API";
import Axios from "axios";
import { toast } from "material-react-toastify";
import contentDisposition from "content-disposition";
import PDFPreview from "components/FilePreview/PDFPreview";
import FilesController from "./FilesController";

const fileController = new FilesController();
let cancelSource = Axios.CancelToken.source();

class DownloadButton extends Component {
  state = {
    downloading: false,
    progress: 0,
    totalLength: 0,
    previewURL: null,
    previewOpen: false,
    previewTitle: "",
  };
  componentDidMount() {}

  componentWillUnmount() {
    fileController.cancelAllTokens();
    cancelSource.cancel();
  }

  handlePreview = (url, filename) => {
    if (url == null) return;
    this.setState({
      previewOpen: true,
      previewURL: url,
      previewTitle: filename,
    });
  };

  handleDirectDownload = (url) => {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    // link.setAttribute("downloa", "_blank");
    link.click();
    document.body.removeChild(link);
  };

  handlePreviewClose = () => {
    this.setState({ previewOpen: false });
  };

  handleSignedURL = async (url) => {
    const data = await fileController.getURL(url);
    return data.uri;
  };

  /**
   *
   * @param {String} href
   * @returns
   */
  startDownload = async (href) => {
    if (href === null) return;

    try {
      const oldHref = href;
      if (href.includes("/v2/")) {
        this.setState({ downloading: true, progress: 0 });
        href = await this.handleSignedURL(href);
      }

      if (oldHref.endsWith(".pdf")) {
        this.handlePreview(href);
        return;
      }

      if (href.includes("digitaloceanspaces")) {
        if (href.includes(".pdf")) {
          this.handlePreview(href);
        } else {
          this.handleDirectDownload(href);
        }
        return;
      }

      this.setState({ downloading: true, progress: 0 });

      if (cancelSource) cancelSource.cancel();
      cancelSource = Axios.CancelToken.source();

      let response = await axios.get(href, {
        onDownloadProgress: this.onDownloadProgress,
        responseType: "blob",
        cancelToken: cancelSource.token,
      });

      const content = response.headers["content-type"];
      const disposition = contentDisposition.parse(
        response.headers["content-disposition"]
      );

      if (content === "application/pdf" && !this.props.forceDownload) {
        let url = URL.createObjectURL(response.data);
        this.handlePreview(url, disposition.parameters.filename);
      } else {
        downloadjs(response.data, disposition.parameters.filename, content);
        if (this.props.onDownloadFinish) this.props.onDownloadFinish();
      }
    } catch (error) {
      if (Axios.isCancel(error)) return;
      console.log(error);
      toast(`Error al descargar archivo`, { type: "error" });
    } finally {
      this.setState({ downloading: false, progress: 0 });
    }
  };

  onDownloadProgress = (progressEvent) => {
    let progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    this.setState({ progress });
  };

  render() {
    const {
      label = "Descargar",
      labelDownloading = "Descargando",
      fileURL = null,
      color = "primary",
    } = this.props;

    let rest = { ...this.props };

    return (
      <React.Fragment>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            variant="outlined"
            color={color}
            {...rest}
            disabled={this.state.downloading}
            onClick={() => this.startDownload(fileURL)}
          >
            {this.state.downloading ? labelDownloading : label}
          </Button>
          {this.state.downloading && (
            <div style={{ width: "100%", marginTop: 10 }}>
              <LinearProgress
                variant="determinate"
                value={this.state.progress}
                color={color}
              />
            </div>
          )}
        </div>
        <PDFPreview
          open={this.state.previewOpen}
          file={this.state.previewURL}
          title={this.state.previewTitle}
          onClose={this.handlePreviewClose}
        />
      </React.Fragment>
    );
  }
}

export default DownloadButton;
