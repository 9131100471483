import React, { Component } from "react";
import styles from "./LoginForm-styles";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import GoogleButton from "react-google-button";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";

import {
  OpenGoogleSignIn,
  GetGoogleRedirectResult,
  SignIn,
} from "Pages/Login/Authentication/authentication";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { toast } from "material-react-toastify";

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.checkRedirectResult = this.checkRedirectResult.bind(this);
  }

  componentDidMount() {
    this.checkRedirectResult();
  }

  async checkRedirectResult() {
    try {
      const result = await GetGoogleRedirectResult();
      let hasUser = result != null && result.user != null;
      this.setState({ redirectResult: !hasUser });
      this.props.onRedirectResult(result);
    } catch (error) {
      console.log(error);
      this.props.handleError(error);
      this.setState({ redirectResult: true });
    }
  }

  state = {
    loadingLogin: false,
    redirectResult: false,
    showingPassword: false,
    resetPassword: {
      email: "",
      loading: false,
      open: false,
    },
  };

  async login(e, email, password, handleStart, handleCompletion, handleError) {
    e.preventDefault();
    handleStart();
    this.setState({ loadingLogin: true });

    try {
      await SignIn(email, password);
      this.setState({ loadingLogin: false });
      handleCompletion();
    } catch (e) {
      if (e == `Cancel`) return;
      this.setState({ loadingLogin: false, email: "", password: "" });
      handleError(e);
    }
  }

  render() {
    const { classes } = this.props;
    const { loadingLogin } = this.state;
    const { email, password, disabled = false } = this.props;
    const buttonDisabled = disabled || loadingLogin;

    if (!this.state.redirectResult) return <CircularProgress />;

    return (
      <React.Fragment>
        <Typography variant="h4" color="textSecondary">
          Iniciar Sesión
        </Typography>
        <br />

        <form className="form-container">
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              autoFocus
              autoComplete="email"
              color="primary"
              variant="filled"
              type="text"
              label={"Correo"}
              size={"small"}
              value={email}
              inputRef={this.props.emailInputRef}
              onChange={(e) => this.props.handleTextChange(e, "email")}
              required
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <TextField
              variant="filled"
              color="primary"
              autoComplete="current-password"
              type="password"
              type={!this.state.showingPassword ? "password" : "text"}
              label={"Contraseña"}
              size={"small"}
              value={password}
              inputRef={this.props.passwordInputRef}
              onChange={(e) => this.props.handleTextChange(e, "password")}
              required
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={this.state.showingPassword}
                  onChange={() => {
                    this.setState({
                      showingPassword: !this.state.showingPassword,
                    });
                  }}
                  color="primary"
                />
              }
              label="Mostrar Contraseña"
            />
          </FormControl>
          <FormControl className={classes.formControl} fullWidth>
            <Button
              type="submit"
              variant="contained"
              color={"primary"}
              onClick={(e) =>
                this.login(
                  e,
                  email,
                  password,
                  this.props.handleStart,
                  this.props.handleCompletion,
                  this.props.handleError
                )
              }
              disabled={buttonDisabled}
              className={classes.button}
            >
              Iniciar Sesión
            </Button>
            <br />
            <Button
              variant="text"
              size="small"
              color={"primary"}
              onClick={(e) => {
                this.handleResetDialogOpen(true);
              }}
              disabled={buttonDisabled}
              className={classes.button}
            >
              Olvide mi contraseña
            </Button>

            {loadingLogin && (
              <CircularProgress
                size={24}
                className={classes.buttonProgress}
                color="primary"
              />
            )}
          </FormControl>
        </form>
        <br />
        <br />
        <Divider />
        <br />
        <Typography variant="body2">Otras formas de iniciar sesión</Typography>
        <div className="google-login-container">
          <GoogleButton
            disabled={buttonDisabled}
            type="dark"
            label="Inicia sesión con Google"
            onClick={() => {
              OpenGoogleSignIn();
            }}
          />
        </div>
        <Dialog
          open={this.state.resetPassword.open}
          onClose={() => this.handleResetDialogOpen(false)}
          fullWidth
        >
          <DialogTitle>Restablecer Contraseña</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa tu correo electrónico, y se te enviará un correo con el
              cual podrás restablecer tu contraseña
            </DialogContentText>
            <TextField
              variant="filled"
              color="primary"
              autoComplete="email"
              type="email"
              label={"Correo"}
              size={"small"}
              value={this.state.resetPassword.email}
              required
              autoFocus
              onChange={(e) => {
                this.setState({
                  resetPassword: {
                    ...this.state.resetPassword,
                    email: e.target.value,
                  },
                });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              color={"primary"}
              disabled={this.state.resetPassword.loading}
              onClick={() => this.handleResetDialogOpen(false)}
            >
              Cancelar
            </Button>
            <Button
              color={"primary"}
              disabled={
                this.state.resetPassword.loading ||
                this.state.resetPassword.email == ""
              }
              onClick={() => {
                this.handleResetPassword();
              }}
            >
              Restablecer
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  handleResetPassword = async () => {
    try {
      await sendPasswordResetEmail(getAuth(), this.state.resetPassword.email);
      this.handleResetDialogOpen(false);
      toast(
        "Se te ha enviado un correo electrónico para que restablezcas tu contraseña",
        { type: "success" }
      );
    } catch (error) {
      toast("Error al enviar el correo de restablecimiento", {
        type: "error",
      });
    }
  };

  handleResetDialogOpen = async (val) => {
    this.setState({
      resetPassword: { ...this.state.resetPassword, open: val },
    });
  };
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  blockToolbar: PropTypes.bool,
};

export default withStyles(styles)(LoginForm);
