import { Select, TextField } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import { Card } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Chip } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import DownloadButton from "components/DownloadButton/DownloadButton";
import { FetchDataComponent } from "components/Fetching/FetchDataComponent";
import { textDataRender } from "components/Fetching/fetchingLoaders";
import GetData from "components/Fetching/GetData";
import Authentication from "controller/Authentication";
import School from "controller/School";
import ClassroomManagementController from "Pages/Teacher/Classroom/Management/ClassroomManagementController";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { getPeriodName, periods } from "utils/Settings";
import SecretaryClassroomController from "../SecretaryClassroomController";

const classroomController = new SecretaryClassroomController();

function loadingCards(prefix) {
  let style = {
    width: "100%",
    height: "100%",
    minHeight: 275,
    borderRadius: 20,
  };
  return (
    <React.Fragment>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_01"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_02"}>
        <Skeleton variant="rect" style={style} />
      </Grid>{" "}
      <Grid item xl={3} lg={4} md={6} sm={12} xs={12} key={prefix + "_03"}>
        <Skeleton variant="rect" style={style} />
      </Grid>
    </React.Fragment>
  );
}
class Notes extends Component {
  state = {
    currentSection: 0,
    sections: [],
    currentClassrooms: [],
    filteredClassrooms: [],
    loading: true,
    currentPeriod: 0,
  };

  constructor(props) {
    super(props);
    this.requestClassroomsRef = React.createRef();
  }

  async componentDidMount() {
    await this.requestSections();
  }

  onPeriodChange() {
    let { currentPeriod, currentClassrooms } = this.state;

    let filteredClassrooms = currentClassrooms.filter((el) => {
      if (this.state.currentPeriod < el.currentPeriod) {
        return el;
      }
    });

    this.setState({ filteredClassrooms });
  }

  findMaxPeriod = (cb) => {
    let currentClassrooms = this.state.currentClassrooms;

    let max = 0;
    currentClassrooms.forEach((el) => {
      if (el.currentPeriod - 1 >= max) max = el.currentPeriod - 1;
    });

    this.setState({ currentPeriod: max }, cb);
  };

  async requestSections() {
    const sections = await School.getSections();

    if (sections.length > 0) {
      this.setState({ sections, currentSection: sections[0] }, () =>
        this.onChange()
      );
    } else {
      this.setState({ sections: [] });
    }
  }

  componentWillUnmount() {
    classroomController.cancelAllTokens();
  }

  onChange = () => {
    this.requestClassroomsRef.current.get();
  };

  requestClassrooms = async () => {
    const data = await classroomController.getClassrooms(
      null,
      this.state.currentSection
    );
    return data;
  };

  onGetClassrooms = (data) => {
    this.setState({ currentClassrooms: data }, () => {
      this.findMaxPeriod(() => this.onPeriodChange());
    });
  };

  render() {
    return (
      <React.Fragment>
        <GetData
          ref={this.requestClassroomsRef}
          request={this.requestClassrooms}
          onData={this.onGetClassrooms}
          onLoading={(loading) => this.setState({ loading })}
          errorMessage={"Error al traer salones"}
          disableSuccessNotification
          dontExecuteOnStart
        />

        <Container style={{ marginTop: 20, textAlign: "left" }}>
          <Typography variant="subtitle1">Notas</Typography>
          <Autocomplete
            id="section-selection"
            options={this.state.sections}
            getOptionLabel={(option) => option.name}
            style={{ marginTop: "20px", width: "100%", maxWidth: "400px" }}
            onChange={(e, newVal) => {
              this.setState({ currentSection: newVal }, () => {
                if (newVal != null) {
                  this.onChange();
                }
              });
            }}
            value={this.state.currentSection}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sección"
                variant="outlined"
                autoFocus={!isMobile}
              />
            )}
          />

          <Autocomplete
            id="section-selection"
            options={periods()}
            getOptionLabel={(option) => getPeriodName(option)}
            style={{ marginTop: "20px", width: "100%", maxWidth: "400px" }}
            onChange={(e, newVal) => {
              this.setState({ currentPeriod: newVal }, () => {
                if (newVal != null) {
                  this.onPeriodChange();
                  // this.onChange();
                }
              });
            }}
            value={this.state.currentPeriod}
            renderInput={(params) => (
              <TextField {...params} label="Unidad" variant="outlined" />
            )}
          />

          <div style={{ marginTop: 20 }} />

          <Divider />

          <div style={{ marginTop: 20 }} />

          <Grid container spacing={2}>
            <FetchDataComponent
              loading={this.state.loading}
              data={this.state.filteredClassrooms}
              // error={this.state.approved.error}
              customNoDataRenderer={() =>
                textDataRender("No se encontraron salones")
              }
              loadingRender={() => loadingCards("approved")}
            >
              {this.state.filteredClassrooms.map((el) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Card variant="outlined" style={{ height: "100%" }}>
                      <CardMedia
                        src={el.imageURL}
                        component="img"
                        height={200}
                        title={el.name}
                      />
                      <CardContent>
                        <Typography variant="subtitle1">{el.name}</Typography>
                        <Typography variant="body1">
                          {Authentication.formatName(el.userName, el.lastName)}
                        </Typography>
                        {/* <Chip label={getPeriodName(el.currentPeriod)}></Chip> */}
                      </CardContent>
                      <CardActions>
                        <DownloadButton
                          fileURL={`${process.env.REACT_APP_API_URL}api/secretary/notes?idClassroom=${el.id}&period=${this.state.currentPeriod}`}
                          color="primary"
                          variant="text"
                          label="Descargar"
                        />
                        {/* <Button color="primary">Descargar</Button> */}
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </FetchDataComponent>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default Notes;
